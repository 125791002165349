<template>
  <div class="lottery-trend-following">
    <div class="header">
      <div class="title-box">
        <p class="title">跟随走势</p>
        <intro-default cont=""></intro-default>
      </div>
      <div class="search-box">
        <div class="switch_fn_bt" @click="rlt_show">{{ is_rlt ? '隐藏' : '展开' }}结果</div>
        <select-user type="1" @change="page_change"></select-user>
      </div>
    </div>
    <div class="lz_cont">
      <ul class="switch">
        <li :class="{ active: indexForSelectRank === i }" v-for="(name, i) in rankNames" :key="name"
          @click="indexForSelectRank = i">{{ name }}</li>
      </ul>
      <div class="table" id="table">
        <table cellpadding="0" cellspacing="0" class="table-data" width="100%" ref="table">
          <thead>
            <tr>
              <th width="180px" rowspan="2" colspan="1">开奖时间
              </th>
              <th width="160" rowspan="2" colspan="1">期号</th>
              <th v-if="is_rlt" width="360" rowspan="2" colspan="1">开奖号码</th>
              <th rowspan="2" colspan="1">
                <div class="number-th">
                  <span>同位开奖号码分布</span>
                  <span class="table_check"><el-checkbox v-model="sameCodeDisplay">显示同位号码</el-checkbox></span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="row in tableData" :key="row.id" height="37">
              <td colspan="1" rowspan="1">{{ row.opentime }}</td>
              <td colspan="1" rowspan="1">{{ row.expect }}</td>
              <td v-if="is_rlt" colspan="1" rowspan="1">
                <div class="opencode">
                  <template v-for="(number, i) in row.opencode">
                    <number :value="number" :key="number" :lottery-code="code"
                      :lhc_icon="row.opencode.length === 7 && i == 6"></number>
                  </template>
                </div>
              </td>
              <td colspan="1" rowspan="1">
                <div class="number-td">
                  <span v-for="lotteryNumber in row.codes" :key="lotteryNumber" class="item">
                    <number :value="lotteryNumber" v-if="lotteryNumber === row.highlightCode" ref="hots" class="code"
                      :lottery-code="code"></number>
                    <span v-else-if="sameCodeDisplay && lotteryNumber === sameCode" class="same"
                      :style="{ color: sameCodeColor, borderColor: sameCodeColor }">{{ lotteryNumber }}</span>
                    <span v-else>{{ lotteryNumber }}</span>
                  </span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <trend-line-canvas v-for="obj in lineObjects" :key="obj.id" :width="obj.width" :height="obj.height"
          :start="obj.start" :end="obj.end" :color="obj.color" :line-width="obj.lineWidth" :top="obj.top"
          :left="obj.left"></trend-line-canvas>
      </div>
    </div>
  </div>
</template>

<script>
import { Loading, Select, Option, Checkbox } from 'element-ui'
import TrendLineCanvas from '@components/pc/TrendLineCanvas'
import randomString from '@plugins/random'
import moment from 'moment'
import Number from '@components/pc/Number'

export default {
  name: 'LotteryTrendFollowing',
  props: {
    code: String
  },
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Checkbox.name]: Checkbox,
    [TrendLineCanvas.name]: TrendLineCanvas,
    [Number.name]: Number
  },
  data() {
    return {
      per_page: 10,
      is_rlt: true,
      data: null,
      loading: null,
      sameCodeDisplay: false,
      options: [
        {
          label: '40期',
          value: 30
        },
        {
          label: '90期',
          value: 90
        },
        {
          label: '今日所有',
          value: null
        }
      ],
      page: null,
      lineObjects: [],
      numbers: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
      numberColors: ['#aaaa3f', '#0089FF', '#4D4D4D', '#FF7300', '#13a3a3', '#5200FF', '#BAB9B9', '#FF0000', '#760000', '#28C300'],
      indexForSelectRank: 0
    }
  },
  computed: {
    tableData() {
      if (!this.data) return []
      const array = []
      for (let i = 0; i < this.data.data.length; i++) {
        const { id, opentime, expect, list, opentimestamp, opencode } = this.data.data[i]
        var row = {
          id,
          codes: list
        }
        row.opentime = `${opentime}`
        row.expect = `${expect}`
        row.highlightCode = this.data.list[this.indexForSelectRank][i]
        row.sum = this.data.sum[this.indexForSelectRank][i]
        row.opencode = opencode.toString().split(',')
        array.push(row)
      }
      return array
    },
    rankNames() {
      if (!this.data) return []
      return this.data.titles || []
    },
    sameCode() {
      return this.numbers[this.indexForSelectRank]
    },
    sameCodeColor() {
      return this.numberColors[this.indexForSelectRank]
    }
  },
  watch: {
    code: function (val, oldVal) {
      this.per_page = 10
      this.requestGetHistory()
    },
    page: function (val, oldVal) {
      this.requestGetHistory()
    },
    indexForSelectRank: function (val, oldVal) {
      this.$nextTick(() => {
        this.drawLine()
      })
    }
  },
  mounted() {
    this.requestGetHistory()
  },
  methods: {
    page_change(value) {
      this.per_page = value
      this.requestGetHistory()
    },
    rlt_show() {
      this.is_rlt = !this.is_rlt
      this.$nextTick(() => {
        this.drawLine()
      })
    },
    refresh() {
      this.requestGetHistory()
    },
    hideTableHeader({ row, column, rowIndex, columnIndex }) {
      return {}
    },
    tableCellStyle({ row, column, rowIndex, columnIndex }) {
      return {}
    },
    requestGetHistory() {
      this.showTableLoading()
      this.$api.followTrend(this.code, this.per_page).then((res) => {
        this.data = res.data
        this.hideTableLoading()
        this.$nextTick(() => {
          this.drawLine()
        })
      }).catch((err) => {
        this.hideTableLoading()
        this.$message.error(err)
      })
    },
    showTableLoading() {
      if (this.loading) return
      this.loading = Loading.service({
        target: '#table',
        fullscreen: false,
        background: 'rgba(0, 0, 0, 0)'
      })
    },
    hideTableLoading() {
      this.$nextTick(() => {
        this.loading.close()
        this.loading = null
      })
    },
    drawLine() {
      this.lineObjects = []

      const lineWidth = 1

      const hots = this.$refs.hots

      const tableRect = this.$refs.table.getBoundingClientRect()

      var lastCenter = null

      for (let i = 0; i < hots.length; i++) {
        const hot = hots[i]
        const hotRect = hot.$el.getBoundingClientRect()
        const center = {
          x: hotRect.x - tableRect.x + hotRect.width / 2,
          y: hotRect.y - tableRect.y + hotRect.height / 2
        }

        if (!lastCenter) {
          lastCenter = center
          continue
        }

        var obj = {
          lineWidth,
          color: '#979797',
          id: randomString(7)
        }

        const canvasWidth = Math.abs(lastCenter.x - center.x)
        const canvasHeight = Math.abs(lastCenter.y - center.y)

        obj.width = canvasWidth
        obj.height = canvasHeight

        if (lastCenter.x > center.x) {
          obj.start = {
            x: canvasWidth,
            y: 0
          }
          obj.end = {
            x: 0,
            y: canvasHeight
          }

          obj.left = center.x
          obj.top = lastCenter.y
        } else if (lastCenter.x < center.x) {
          obj.start = {
            x: 0,
            y: 0
          }
          obj.end = {
            x: canvasWidth,
            y: canvasHeight
          }

          obj.left = lastCenter.x
          obj.top = lastCenter.y
        } else {
          obj.start = {
            x: lineWidth / 2,
            y: 0
          }
          obj.end = {
            x: lineWidth / 2,
            y: canvasHeight
          }

          obj.left = (lastCenter.x - lineWidth / 2)
          obj.top = lastCenter.y
          obj.width = lineWidth
        }

        this.lineObjects.push(obj)

        lastCenter = center
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@scss/pc/variables';

.lottery-trend-following {
  >.header {
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    border-bottom: thin $color-border solid;

    >.title-box {
      display: flex;
      align-items: center;
      height: 49px;

      >.title {
        font-size: 20px;
        font-weight: 400;
        color: #222;
      }
    }

    >.search-box {
      display: flex;
      align-items: center;

      >.title {
        margin-right: 15px;
        font-size: 14px;
        color: #222;
      }

      >.icon-search {
        margin-left: 10px;
        width: 48px;
        height: 24px;
        display: block;
      }
    }
  }

  .lz_cont {
    padding: 20px;
    padding-top: 30px;
    padding-bottom: 0;
    box-sizing: border-box;
  }

  .switch {
    list-style: none;
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
    margin-bottom: 26px;

    >li {
      margin-right: 10px;
      cursor: pointer;
      list-style: none;
      padding: 0 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: #222;
      height: 26px;
      border-radius: 50px;

      &.active {
        color: #FA3E3E;
        background-color: #FEEBEB;
      }
    }
  }

  .table {
    position: relative;

    >.table-data {
      table-layout: fixed;
      word-break: break-all;
      word-wrap: break-word;
      border-collapse: separate;
      border: 1px solid #d6d6d6;
      border-right: none;
      border-bottom: none;

      .el-table thead {
        color: #919191;
        font-weight: bold;
      }

      td,
      th {
        min-width: 0;
        box-sizing: border-box;
        text-overflow: ellipsis;
        vertical-align: middle;
        position: relative;
        text-align: center;
        border-bottom: 1px solid #d6d6d6;
        border-right: 1px solid #d6d6d6;
        font-size: 14px;
      }

      th {
        height: 37px;
        color: #919191;
        font-weight: bold;
        background-color: #f9f9f9;
      }

      td {
        color: rgba(0, 0, 0, 0.8);
      }

      .number-th {
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          display: flex;
          align-items: center;
          justify-content: center;

          &:first-child {
            margin-right: 20px;
          }
        }
      }

      .number-td {
        display: flex;
        justify-content: center;
        align-items: center;

        >.item {
          flex: 1;
          height: 37px;
          box-sizing: border-box;
          display: flex;
          justify-content: center;
          align-items: center;

          &+.item {
            border-left: 1px solid #d6d6d6;
          }

          >.code {
            position: relative;
            z-index: 100;
          }

          >.same {
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 30px;
            height: 30px;
            border-radius: 15px;
            border-width: 1px;
            border-style: solid;
          }
        }
      }

      .lifting {
        display: flex;
        justify-content: center;
        align-items: center;

        >img {
          margin-left: 14px;
          width: auto;
          height: 14px;
        }
      }
    }

    .opencode {
      color: #fa3e3e;

      span+span {
        margin-left: 5px;
      }
    }

    .hot {
      position: relative;
      z-index: 100;
      display: inline-block;
      color: white;
      background: #07A6F8;
      width: 24px;
      height: 24px;
      line-height: 24px;
      font-size: 14px;
      text-align: center;
      border-radius: 50%;
    }
  }
}

.sum-tip {
  padding: 12px;

  >.title {
    font-size: 14px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.8);
  }

  >.content {
    margin-top: 8px;
    line-height: 20px;
    font-size: 12px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
  }
}

.switch_fn_bt {
  margin-right: 20px;
  width: 80px;
  height: 24px;
  border-radius: 4px;
  line-height: 24px;
  font-size: 14px;
  text-align: center;
  background-color: #F9F9F9;
  color: #222;
  border: 1px solid #E3E3E6;
  cursor: pointer;
}
</style>

<style lang="scss">
.table_check .el-checkbox {
  color: #C2C2C2 !important;
}
</style>
